<template lang="pug">
    div(class="sz-EditConfiguration")
        div(class="sz-EditConfiguration-header")
            div(class="sz-EditConfiguration-header-title") {{ $t(`deviceManagement.renameConfiguration.editDescription`) }}
        div(class="sz-EditConfiguration-body")
            div(class="sz-EditConfiguration-body-section")
                div(class="sz-EditConfiguration-body-section-title") {{ $t(`deviceManagement.renameConfiguration.headers.description`) }}
                FormSectionInput(
                    title="",
                    textSize="content",
                    :defaultInput="defaultDescription",
                    @updated="updateDescription",
                    :useInputTag="changeableDescription",
                    class="sz-EditConfiguration-body-section-input")
        div(class="sz-EditConfiguration-buttons")
            div(
                @click.stop="cancel()",
                class="sz-EditConfiguration-buttons-cancel") {{ $t(`management.cancel`) }}
            div(
                @click.stop="save()",
                :class="'sz-EditConfiguration-buttons-save-' + ((settingsHaveChanged && isDescriptionValid) ? 'valid' : 'invalid')",
                class="sz-EditConfiguration-buttons-save") {{ $t(`management.save`) }}
        div(
            v-if="updatingConfiguration",
            class="sz-EditConfiguration-loading")
            LoadingSpinner

</template>

<script>
    import { mapGetters } from 'vuex'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import constructModalSettings from 'mixins/modalSettings'
    import constants from 'helpers/constants'
    import formValidator from 'mixins/formValidator'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import EventBus from 'src/eventBus'

    export default {
        name: "EditConfiguration",
        components: {
            FormSectionInput,
            LoadingSpinner,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            config: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                newDescription: null,
                showConfirmModal: true,
            }
        },

        computed: {
            ...mapGetters([
                'updatingConfiguration',
                'updateConfigurationStatus',
            ]),

            defaultDescription () {
                return this.config.description
            },

            settingsHaveChanged () {
                if (!this.config.description) {
                    return this.newDescription !== null
                        && this.newDescription.trim().length != 0
                }

                return this.newDescription !== null
                    && this.newDescription.toLowerCase().trim() !== this.config.description.toLowerCase().trim()
                    && this.newDescription.trim().length != 0
            },

            newSettings () {
                return {
                    id: this.config.id,
                    description: this.newDescription,
                }
            },

            isUserConfirmed () {
                return this.showConfirmModal === false
            },

            updateSucceeded () {
                return this.updateConfigurationStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS
            },

            changeableDescription () {
                return true
            },

            isDescriptionValid() {
                return !this.isDescriptionTooShort
            },

            isDescriptionTooShort() {
                return !!(this.newDescription.length === 0)
            },
        },

        watch: {
            showConfirmModal: {
                //watches if submit is confirmed by user & calls submit again
                handler: function () {
                    if (this.isUserConfirmed) {
                        this.save()
                    }
                },
            },
        },

        mounted () {
            EventBus.$on('USER_CONFIRMED', () => {
                this.showConfirmModal = false
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.showConfirmModal = true
            })
        },

        methods: {
            updateDescription (desc) {
                this.newDescription = desc
            },

            cancel () {
                this.$emit('closeEdit')
            },

            async save() {
                if (!this.settingsHaveChanged) return
                if (!this.isDescriptionValid) {
                    return this.failModal(
                        this.$t(`deviceManagement.renameConfiguration.descriptionWarningMessage`),
                        this.$t(`deviceManagement.renameConfiguration.descriptionWarningTitle`),
                        () => this.showConfirmModal = true)
                }

                if (this.showConfirmModal) {
                    return this.confirmModal(
                        this.$t(`deviceManagement.renameConfiguration.editDescription`),
                        this.$t(`deviceManagement.renameConfiguration.editConfigurationConfirm`, { newDesc: this.newSettings.description }))
                }

                switch(this.config.type) {
                    case constants.DEVICE_CONFIG_API_TYPES.FIRMWARE:
                        await this.$store.dispatch('renameFirmware', this.newSettings)
                        break
                    case constants.DEVICE_CONFIG_API_TYPES.NETWORK:
                        await this.$store.dispatch('renameNetworkConfig', this.newSettings)
                        break
                    case constants.DEVICE_CONFIG_API_TYPES.OPERATIONS:
                        await this.$store.dispatch('renameOperationsConfig', this.newSettings)
                        break
                    case constants.DEVICE_CONFIG_API_TYPES.AZURE:
                        await this.$store.dispatch('renameAzureConfig', this.newSettings)
                        break
                    default:
                        this.failModal(
                            this.$t("pleaseTryAgain"),
                            this.$t(`deviceManagement.renameConfiguration.unrecognizedType`),
                            () => this.showConfirmModal = true)
                }

                if (this.updateSucceeded) {
                    this.successModal(
                        this.$t(`deviceManagement.renameConfiguration.updateMessage`, { newDesc: this.newSettings.description }),
                        true,
                        () => EventBus.$emit('REFRESH_CONFIGURATIONS'),
                        () => this.$store.dispatch('closeAllModals'))
                } else {
                    this.failModal(
                        this.$t("pleaseTryAgain"),
                        this.$t("somethingWentWrong"),
                        () => this.showConfirmModal = true)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-EditConfiguration {
        width: 20vw;
        min-width: 20rem;
        position: relative;

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-header {
            width: 100%;
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem 0;
            font-size: 13px;
            @extend %font-heading;

            &-title {
                margin-left: 1rem;
            }
        }

        &-body {
            background-color: $color-box-background;
            width: 100%;
            height: 8vh;

            &-section {
                display: flex;
                padding-top: 1rem;

                &-title {
                    @extend %font-heading;
                    font-size: 0.75rem;
                    margin: 0.3rem 1.5rem;
                }

                &-input {
                    width: 64%;
                }
            }
        }

        &-buttons {
            background-color: $color-box-background;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 1rem;
            @extend %font-topbar-heading;

            &-cancel {
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
                font-size: 12px;
                align-items: center;
            }

            &-save {
                @extend %button-layout;
                padding: 0.5rem 1rem;
                border-radius: 0;

                &-invalid {
                    background-color: $color-button-disabled-bg;
                    color: $color-unselected-text;
                    cursor: not-allowed;
                }

                &-valid {
                    background-color: $color-lifebooster-light-green;
                }
            }
        }
    }
</style>
