<template>
  <div class="sz-DeviceManagementDashboard">
    <div class="sz-DeviceManagementDashboard-sections">
      <div class="sz-DeviceManagementDashboard-section">
        <div class="sz-DeviceManagementDashboard-section-buttons">
          <div
            class="sz-DeviceManagementDashboard-section-buttons-button"
            :class="
              `sz-DeviceManagementDashboard-section-buttons-button` +
              (isManageDevices ? '-selected' : 'notSelected')
            "
            @click="openManageDevices"
          >
            <SvgIcon
              class="sz-DeviceManagementDashboard-section-buttons-button-icon"
              icon="device"
              width="1rem"
              height="1rem"
            />
            <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
              $t(`deviceManagement.manageDevices`)
            }}</span>
          </div>
          <div
            class="sz-DeviceManagementDashboard-section-buttons-button"
            :class="
              `sz-DeviceManagementDashboard-section-buttons-button` +
              (isStatusConfiguration ? '-selected' : 'notSelected')
            "
            @click="openStatusConfiguration"
          >
            <SvgIcon
              class="sz-DeviceManagementDashboard-section-buttons-button-icon"
              icon="deviceStatus"
              width="1rem"
              height="1rem"
            />
            <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
              $t(`deviceManagement.statusConfiguration.button`)
            }}</span>
          </div>
          <div
            class="sz-DeviceManagementDashboard-section-buttons-button"
            :class="
              `sz-DeviceManagementDashboard-section-buttons-button` +
              (isAssignConfiguration ? '-selected' : 'notSelected')
            "
            @click="openAssignConfiguration"
          >
            <SvgIcon
              class="sz-DeviceManagementDashboard-section-buttons-button-icon"
              icon="deviceConfig"
              width="1rem"
              height="1rem"
            />
            <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
              $t(`deviceManagement.assignConfiguration.button`)
            }}</span>
          </div>
          <div
            class="sz-DeviceManagementDashboard-section-buttons-button"
            :class="
              `sz-DeviceManagementDashboard-section-buttons-button` +
              (isRenameConfiguration ? '-selected' : 'notSelected')
            "
            @click="openRenameConfiguration"
          >
            <SvgIcon
              class="sz-DeviceManagementDashboard-section-buttons-button-icon"
              icon="edit"
              width="1rem"
              height="1rem"
            />
            <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
              $t(`deviceManagement.renameConfiguration.button`)
            }}</span>
          </div>
          <div class="sz-DeviceManagementDashboard-section-buttons-divider" />
          <transition name="slide">
            <div
              v-if="isManageDevices"
              class="sz-DeviceManagementDashboard-section-buttons-manageDevices"
            >
              <div
                v-if="userHasLBAdminRole"
                class="sz-DeviceManagementDashboard-section-buttons-button"
                @click="startBulkUploadDevices"
              >
                <SvgIcon
                  class="sz-DeviceManagementDashboard-section-buttons-button-icon"
                  icon="upload"
                  width="1rem"
                  height="1rem"
                />
                <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
                  $t(`deviceManagement.uploadDevices`)
                }}</span>
              </div>
              <div
                v-if="userHasLBAdminRole"
                class="sz-DeviceManagementDashboard-section-buttons-button"
                @click="startCertificateUpload"
              >
                <SvgIcon
                  class="sz-DeviceManagementDashboard-section-buttons-button-icon"
                  icon="upload"
                  width="1rem"
                  height="1rem"
                />
                <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
                  $t(`deviceManagement.uploadCertificates`)
                }}</span>
              </div>
              <div
                class="sz-DeviceManagementDashboard-section-buttons-button"
                @click="startDownload"
              >
                <SvgIcon
                  class="sz-DeviceManagementDashboard-section-buttons-button-icon-download"
                  icon="upload"
                  width="1rem"
                  height="1rem"
                />
                <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
                  $t(`deviceManagement.downloadTitle`)
                }}</span>
              </div>
            </div>
            <div
              v-if="isStatusConfiguration || isAssignConfiguration || isRenameConfiguration"
              class="sz-DeviceManagementDashboard-section-buttons-manageDevices"
            >
              <div
                class="sz-DeviceManagementDashboard-section-buttons-button"
                @click="startConfigurationUpload"
              >
                <SvgIcon
                  class="sz-DeviceManagementDashboard-section-buttons-button-icon"
                  icon="upload"
                  width="1rem"
                  height="1rem"
                />
                <span class="sz-DeviceManagementDashboard-section-buttons-button-text">{{
                  $t(`deviceManagement.uploadConfiguration`)
                }}</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="sz-DeviceManagementDashboard-section-divider" />
    <component :is="selectedOption" class="sz-CompanySettings-manageSection" />
  </div>
</template>

<script>
import Papa from 'papaparse'
import { mapGetters } from 'vuex'
import { FlatfileButton } from '@flatfile/vuejs'

import constants from 'helpers/constants'
import modalSettings from 'mixins/modalSettings'
import SvgIcon from 'components/Shared/SvgIcon'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

import { listener } from 'helpers/flatfileDeviceBulkUploadListener'
import BatchTable from 'components/DeviceManagementDashboard/BatchTable'
import StatusConfiguration from 'components/DeviceManagementDashboard/StatusConfiguration'
import RenameConfiguration from 'components/DeviceManagementDashboard/RenameConfiguration'
import AssignConfiguration from 'components/DeviceManagementDashboard/AssignConfiguration'
import formValidator from 'mixins/formValidator'

import { initializeFlatfile } from '@flatfile/javascript'
import { configToBlueprint } from '@flatfile/v2-shims'

export default {
  name: 'DeviceManagementDashboard',

  components: {
    LoadingSpinner,
    BatchTable,
    SvgIcon,
    StatusConfiguration,
    RenameConfiguration,
    AssignConfiguration,
    FlatfileButton,
    formValidator,
  },

  mixins: [modalSettings, formValidator],

  data() {
    return {
      results: null,
      selectedOption: constants.DEVICE_SETTINGS.MANAGE_DEVICES,
      licenseKey: process.env.VUE_APP_FLAT_FILE_LICENSE,
      customer: {
        userId: '',
      },
      flatFileSettings: {
        type: 'Devices',
        fields: [
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.SHIPPED}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.SHIPPED,
            validators: [{ validate: 'required' }],
          },
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.SN}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.SN,
            validators: [{ validate: 'required' }, { validate: 'unique' }],
          },
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.POS}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.MANUFACTURING.POS,
            type: 'select',
            options: Object.values(constants.DEVICE_POSITIONS).map((value) => {
              return { value: value, label: value }
            }),
            validators: [
              {
                validate: 'required_with_values',
                fieldValues: {
                  [constants.DEVICE_CHARACTERISTICS.DEVICE_TYPE]:
                    constants.DEVICE_CONFIG_SGD_DEVICE_TYPES,
                },
              },
            ],
          },
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.PROFILE.ATTRIBUTES.SID}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.PROFILE.ATTRIBUTES.SID,
            validators: [{ validate: 'required' }],
          },
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.PROFILE.ATTRIBUTES.DEPLOYMENT_MODE}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.PROFILE.ATTRIBUTES.DEPLOYMENT_MODE,
            validators: [{ validate: 'required' }],
          },
          {
            label: this.$t(
              `deviceManagement.fields.${constants.NESTED_DEVICE_CHARACTERISTICS.DEVICE_TYPE}`
            ),
            key: constants.NESTED_DEVICE_CHARACTERISTICS.DEVICE_TYPE,
            type: 'select',
            options: Object.values(constants.DEVICE_TYPES).map((value) => {
              return { value: value, label: value }
            }),
            validators: [{ validate: 'required' }],
          },
        ],
        autoDetectHeaders: true,
        allowInvalidSubmit: true,
      },
      deviceList: [],
    }
  },

  mounted() {
    this.customer.userId = this.currentCustomer

    window.openFlatfile = ({ publishableKey, environmentId }) => {
      if (!publishableKey && !environmentId) {
        throw new Error(
          'You must provide a publishable key and an environment ID - pass through in index.html'
        )
      }

      const flatfileOptions = {
        publishableKey,
        environmentId,
        name: 'Contact Space',
        workbook: configToBlueprint(this.flatFileSettings),
        listener,
        sidebarConfig: {
          showSidebar: false,
        },
        // Additional props...
      }

      initializeFlatfile(flatfileOptions)
    }
  },

  computed: {
    ...mapGetters([
      'userHasLBAdminRole',
      'downloadingDevices',
      'successfulDeviceDownload',
      'currentCustomer',
    ]),

    isManageDevices() {
      return this.selectedOption === constants.DEVICE_SETTINGS.MANAGE_DEVICES
    },

    isStatusConfiguration() {
      return this.selectedOption === constants.DEVICE_SETTINGS.STATUS_CONFIGURATION
    },

    isAssignConfiguration() {
      return this.selectedOption === constants.DEVICE_SETTINGS.ASSIGN_CONFIGURATION
    },

    isRenameConfiguration() {
      return this.selectedOption === constants.DEVICE_SETTINGS.RENAME_CONFIGURATION
    },
  },

  methods: {
    startCertificateUpload() {
      this.$store.dispatch(
        'showModal',
        this.constructModalSettings(
          constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_UPLOAD_CERTIFICATES,
          false, // Close upon clicking outside window
          {}, // Modal specific settings
          true, // Show close button (x on top right corner)
          true // Dark mode
        )
      )
    },

    startConfigurationUpload() {
      this.$store.dispatch(
        'showModal',
        this.constructModalSettings(
          constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_UPLOAD_CONFIGURATION,
          false, // Close upon clicking outside window
          {}, // Modal specific settings
          true, // Show close button (x on top right corner)
          true // Dark mode
        )
      )
    },

    startBulkUploadDevices() {
      openFlatfile({
        publishableKey: process.env.VUE_APP_FLATFILE_PUBLISHABLE_KEY,
        environmentId: process.env.VUE_APP_FLATFILE_ENVIRONMENT_ID,
      })
    },

    async startCsvUpload(results) {
      try {
        this.results = results.validData

        let invalidSets = this.validateSetsAreMonoDeviceType()
        if (!invalidSets || invalidSets.length > 0) {
          return this.$t('deviceManagement.errorMessage.oneDeviceTypePerSetOnly', {
            sid: invalidSets.join(', '),
          })
        }

        const processedResults = this.processDevices(this.results)
        await this.$store.dispatch('uploadDevices', processedResults)

        return this.$t('deviceManagement.bulkSuccess')
      } catch (err) {
        this.failModal(this.$t('pleaseTryAgain'), this.$t('somethingWentWrong'))
      }
    },

    async startDownload() {
      if (!this.downloadingDevices) {
        try {
          let devices = await this.$store.dispatch('downloadDevices')

          const headers = Object.values(constants.DEVICE_CHARACTERISTICS).map((key) => {
            return this.$t(`deviceManagement.fields.${key}`)
          })

          let data = devices.map((device) => {
            return {
              [headers[0]]: device.manufacturing.shipped || null,
              [headers[1]]: device.manufacturing.sn || null,
              [headers[2]]: device.manufacturing.pos || null,
              [headers[3]]: device.profile.attributes.sid || null,
              [headers[4]]: device.profile.attributes.deploymentMode || null,
              [headers[5]]: device.deviceType || null,
            }
          })

          let unparsed = Papa.unparse({
            fields: [...headers],
            data: [...data],
          })

          const objectUrl = new Blob([unparsed], { type: 'text/csv;charset=utf-8;' })

          const url = window.URL.createObjectURL(objectUrl)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'devices.csv')
          const linkId = 'downloadId'
          link.setAttribute('id', linkId)
          const element = document.body.appendChild(link)
          link.click()

          element.parentNode.removeChild(element)
        } catch (error) {
          console.error(error)
        }
      }
    },

    openManageDevices() {
      this.selectedOption = constants.DEVICE_SETTINGS.MANAGE_DEVICES
    },

    openStatusConfiguration() {
      this.selectedOption = constants.DEVICE_SETTINGS.STATUS_CONFIGURATION
    },

    openAssignConfiguration() {
      this.selectedOption = constants.DEVICE_SETTINGS.ASSIGN_CONFIGURATION
    },

    openRenameConfiguration() {
      this.selectedOption = constants.DEVICE_SETTINGS.RENAME_CONFIGURATION
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.sz-DeviceManagementDashboard {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  color: $color-text;

  &-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-section {
    display: flex;
    padding: 0.8rem;

    &-title {
      height: 2rem;
    }

    &-divider {
      border: 0.5px solid #1a8f99;
      margin-bottom: 1rem;
      opacity: 0.4;
    }

    &-buttons {
      border-radius: 3px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &-divider {
        margin-left: 1.8rem;
        margin-right: 0.5rem;
        border-left: 1.5px solid grey;
        opacity: 0.5;
        height: 2rem;
      }

      &-manageDevices {
        display: flex;
        align-items: center;
      }

      &-button {
        @extend %button-layout;
        @extend %font-heading;
        padding: 0.5rem 1rem;
        font-size: 12px;
        background-color: transparent;
        border: none;
        color: white;
        display: flex;
        align-items: center;

        &-icon {
          margin-top: 0.2rem;
          fill: white;

          &-download {
            transform: rotate(180deg);
          }
        }

        &-text {
          margin-left: 0.5rem;
        }

        &:hover {
          background-color: $color-lifebooster-light-green;
        }

        &-selected {
          background-color: $color-lifebooster-light-green;
        }
      }

      &-flatFileButton {
        ::v-deep button {
          border-radius: 3px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          background-color: transparent;
          border: none;
          color: white;
          display: flex;
          align-items: center;
          font: inherit;
          text-transform: uppercase;
        }
      }
    }
  }
}

.loading {
  position: absolute;
  width: 6.4rem;
  height: 1.25rem;
  padding-top: 0.05rem;
  background-color: lighten($color-box-silver, 30%);
  opacity: 0.5;
  cursor: default;
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter {
  transform: translateX(-10px);
  opacity: 0;
}
.slide-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
