<template lang="pug">
    div(class="sz-AssignConfiguration")
        div(class="sz-AssignConfiguration-addSet")
            div(class="sz-AssignConfiguration-addSet-body")
                FormSectionInput(
                    :title="formTitle",
                    textSize="noAllCaps",
                    @updated="updateSetID",
                    :useInputTag="changeable",
                    :maxLength="maxLength",
                    class="sz-AssignConfiguration-addSet-body-input")
                div(class="sz-AssignConfiguration-addSet-body-buttons")
                    div(
                        @click.stop="addSet()",
                        :class="'sz-AssignConfiguration-addSet-body-buttons-add-' + (settingsHaveChanged && !this.addingSet ? 'valid' : 'invalid')",
                        class="sz-AssignConfiguration-addSet-body-buttons-add") {{ $t(`deviceManagement.assignConfiguration.add`) }}
        div(class="sz-AssignConfiguration-setTable")
            SetTable(
                :sets="sets")
</template>

<script>
    import constants from 'helpers/constants'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import formValidator from 'mixins/formValidator'
    import constructModalSettings from 'mixins/modalSettings'
    import SetTable from 'components/DeviceManagementDashboard/AssignConfiguration/SetTable'
    import EventBus from 'src/eventBus'

    export default {
        name: 'AssignConfiguration',

        components: {
            FormSectionInput,
            formValidator,
            constructModalSettings,
            SetTable,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        data() {
            return {
                setID: '',
                sets: [],
                addingSet: false,
            }
        },

        computed: {
            formTitle() {
                return this.$t(`deviceManagement.assignConfiguration.enterSetID`)
            },

            changeable() {
                return true
            },

            maxLength() {
                return 6
            },

            settingsHaveChanged() {
                if (this.setID.length < 6 || !this.sets.map(set => { return set.setID }).indexOf(this.setID)) {
                    return false
                } else {
                    return true
                }
            },

            fullSetID() {
                return `0x` + this.setID.toUpperCase()
            },
        },

        mounted() {
            EventBus.$on('SET_REMOVED', (setID) => {
                this.sets = this.sets.filter(set => set.setID !== setID)
            })
        },

        beforeDestroy() {
            EventBus.$off('SET_REMOVED')
        },

        methods: {
            updateSetID(setID) {
                this.setID = setID
            },

            removeSetIDPrepend(setID) {
                return (setID.length > 6) ? setID.substring(2) : setID
            },

            async addSet() {
                if (this.settingsHaveChanged && !this.addingSet) {
                    try {
                        this.addingSet = true

                        await this.validateSet()
                        let set = await this.$store.dispatch(
                            'getSetStatus',
                            this.fullSetID,
                        )

                        let trimmedSetId = this.removeSetIDPrepend(set.id)
                        let isDuplicateSet = this.isDuplicateSet(trimmedSetId)

                        // Check if set has already been added
                        if (!isDuplicateSet) {
                            this.sets.push({
                                "setID": trimmedSetId,
                                "network": set.devices[0].desiredStatus.network.description,
                                "firmware": set.devices[0].desiredStatus.firmware.description,
                                "operations": set.devices[0].desiredStatus.operation.description,
                                "azure": set.devices[0].desiredStatus.azure.description,
                                "deviceType": set.devices[0].deviceType,
                            })
                        } else {
                            this.failModal(
                                this.$t("deviceManagement.assignConfiguration.duplicateSetMessage"),
                                this.$t("deviceManagement.assignConfiguration.duplicateSet")
                            )
                        }

                        this.addingSet = false
                    } catch(error) {
                        this.addingSet = false
                        this.failModal(
                            this.$t("modals.modalInitiateCapture.enterValidSet"),
                            this.$t("modals.modalInitiateCapture.invalidSet")
                        )
                    }
                }
            },

            async validateSet() {
                return await this.$store.dispatch(
                    'getDeviceSet',
                    this.fullSetID,
                )
            },

            isDuplicateSet(setID) {
                return Object.values(this.sets).some((set) => set.setID === setID)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-AssignConfiguration {
        display: flex;
        flex-direction: row;

        &-addSet {
            position: relative;

            &-body {
                display: flex;
                flex-direction: column;
                background-color: $color-box-background;
                width: 100%;
                padding: 0.75rem 0.75rem 0.75rem 0.75rem;

                &-input {
                    font-size: 15px;
                }

                &-buttons {
                    background-color: $color-box-background;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding-top: 1rem;
                    @extend %font-topbar-heading;

                    &-add {
                        @extend %button-layout;
                        padding: 0.5rem 1rem;
                        border-radius: 0;

                        &-invalid {
                            background-color: $color-button-disabled-bg;
                            color: $color-unselected-text;
                            cursor: not-allowed;
                        }

                        &-valid {
                            background-color: $color-lifebooster-light-green;
                        }
                    }
                }
            }
        }

        &-setTable {
            padding-left: 3rem;
        }
    }
</style>
