<template lang="pug">
    div(class="sz-BatchTable")
        div(class="sz-BatchTable-table")
            div(class="sz-BatchTable-optionsBar")
            div(class="sz-BatchTable-table-body")
                div(
                    :class="{'loadingBatches': loadingBatches}",
                    class="sz-BatchTable-batchIds")
                    div(class="sz-BatchTable-batchIds-refresh")
                        div(class="sz-BatchTable-title") {{ $t('deviceManagement.batchIds')}}
                        div(
                            class="sz-BatchTable-batchIds-refresh-button",
                            @click="getBatches")
                            SvgIcon(
                                icon="refresh",
                                width="1rem", 
                                height="1rem",
                                class="sz-BatchTable-batchIds-refresh-icon")
                    div(class="sz-BatchTable-batchIds-cells")
                        LoadingSpinner(
                            v-if="loadingBatches",
                            class="loading")
                        div(
                            v-for="batch in batches",
                            @click="checkBatch(batch.id)",
                            class="sz-BatchTable-batchIds-cells-cell",
                            :class="{'color-batch': selectedBatch === batch.id,}")
                            div(class="sz-BatchTable-batchId") {{ batch.id }}
                div(class="sz-BatchTable-batchInfo")
                    div(class="sz-BatchTable-batchInfo-info")
                        div(class="sz-BatchTable-title") {{ $t('deviceManagement.jobs')}}
                        div(class="sz-BatchTable-batchInfo-info-jobs")
                            div(
                                v-for="job in jobs",
                                @click="selectJob(job)",
                                class="sz-BatchTable-batchInfo-info-jobs-job") 
                                div(class="sz-BatchTable-batchInfo-info-jobs-job-title",
                                    :class="`color-job-` + (isSelectJob(job.location) ? job.status : 'notSelected')") {{ job.location }}
                                div(:class="`color-job-${job.status} job-bar`")
        div(
            v-if="selectedJobBelongsToJobs"
            class="sz-BatchTable-batchInfo-extraInfo",)
            BatchJobInfo(
                :jobTime="jobTime",
                :jobStatus="jobStatus",
                :jobOperation="jobOperation",
                :jobMessage="jobMessage",
                :jobLocation="jobLocation"
            )
</template>

<script>
    import moment from 'moment-timezone'
    import { mapGetters } from 'vuex'
    import LoadingSpinner from "components/Shared/LoadingSpinner"
    import BatchJobInfo from './BatchJobInfo'
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: 'BatchTable',

        components: {
            LoadingSpinner,
            BatchJobInfo,
            SvgIcon,
        },

        data () {
            return {
                batches: [],
                selectedBatch: null,
                selectedJob: null,
                batchInfo: '',
            }
        },

        computed: {
            ...mapGetters([
                'loadingBatches',
            ]),
            jobs () {
                return this.batchInfo.jobs && this.batchInfo.jobs.length > 0 ? this.batchInfo.jobs : null
            },


            selectedJobBelongsToJobs () {
                return this.jobs ? this.selectedJob && this.jobs.some(job => job.location === this.selectedJob.location) : false
            },

            jobTime () {
                return moment(this.selectedJob.timestamp).format() || ''
            },

            jobStatus () {
                return this.selectedJob.status || ''
            },

            jobOperation () {
                return this.selectedJob.operation || ''
            },

            jobMessage () {
                return this.selectedJob.message || ''
            },

            jobLocation () {
                return this.selectedJob.location || ''

            },
        },

        mounted () {
            this.getBatches()
        },

        methods: {
            async getBatches () {
                this.batches = await this.$store.dispatch('getDeviceBatches')
            },

            async checkBatch (batchID) {
                this.batchInfo = await this.$store.dispatch('getBatchStatus', batchID)
                this.selectedJob = null
                this.selectedBatch = batchID
            },

            selectJob (job) {
                this.selectedJob = job
            },

            isSelectJob (job) {
                return this.selectedJob && this.selectedJob.location === job
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-BatchTable {
        display: flex;
        flex-direction: row;
        padding: 0.5rem 0;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 1.25rem;
            @extend %font-heading;

        }

        &-table {
            margin-right: 1rem;
            display: flex;
            flex-direction: column;
            width: 72%;

            &-body {
                display: flex;
                height: 65vh;
            }
        }

        &-title {
            @extend %font-heading;
            padding: 1rem 0;     
        }


        &-batchIds {
            width: 30%;
            min-width: 10rem;
            flex-grow: 1;
            background-color: $color-box-background;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            &-refresh {
                display: flex;
                align-items: center;
                margin: 0 0.5rem;
                margin-left: 1.45rem;

                &-button {
                    cursor: pointer;
                    margin-left: 0.5rem;
                    margin-top: 0.3rem;
                }
            }

            &.loadingBatches {
                overflow: hidden;
            }

            &-cells {
                width: 100%;
                overflow-y: scroll;
                @include scrollbar-widget;
                &-cell {
                    padding: 0.75rem;
                    margin: 0.27rem 0.7rem;
                    margin-bottom: 0.98rem;
                    cursor: pointer;

                    &:hover {
                        background-color: darken($color-box-background, 5%);
                    }
                }
            }
        }

        &-batchInfo {
            display: flex;
            flex-direction: column;
            width: 80%;
            max-height: 100%;
            flex-grow: 1;
            background-color: darken($color-box-background,1%);
            color: $color-table-font-black;
            font-size: 0.75rem;

            &-extraInfo {
                width: 25%;
            }

            &-info {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 100%;

                &-jobs {
                    display: flex;
                    flex-direction: column;
                    padding: 0 0.5rem;
                    color: $color-table-font-black;
                    font-size: 0.75rem;
                    overflow-y: auto;
                    @include scrollbar-widget;

                    &-job {
                        @extend %font-content;
                        margin: 0.45rem;
                        cursor: pointer;

                        &-title {
                            background-color: darken($color-box-background, 5%);
                            padding: 0.65rem;
                        }
                    }

                    &-jobInfo {
                        flex-grow: 1;
                        padding: 0.5rem;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    .color {
        &-job {

            &-PENDING {
                background-color: #7C7C7C;
            }

            &-SUCCESS {
                background-color: #27AE60;
            }

            &-ERROR {
                background-color: darken($color-risk-red, 5%);
            }
        }

        &-batch {
            background-color: darken($color-box-background, 5%);
        }
    }

    .job-bar {
        height: 0.25rem;
    }

    .loading {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: lighten($color-box-silver, 30%);
        opacity: .5;
        cursor: default;
        border-radius: 0.19rem;
        margin-top: -0.5rem;
    }
</style>