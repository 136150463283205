<template>
  <div class="sz-StatusConfiguration">
    <div class="sz-StatusConfiguration-setTable">
      <div class="sz-StatusConfiguration-setTable-optionsBar">
        <div class="sz-StatusConfiguration-setTable-optionsBar-title">
          {{ $t(`deviceManagement.statusConfiguration.setStatus`) }}
        </div>
        <div class="sz-StatusConfiguration-setTable-optionsBar-search">
          <input
            v-model="search"
            class="sz-StatusConfiguration-setTable-optionsBar-search-input"
            type="text"
            placeholder="Type here to search"
          />
          <SvgIcon
            class="sz-StatusConfiguration-setTable-optionsBar-search-icon"
            icon="search"
            width="0.5rem"
            height="0.5rem"
          />
        </div>
      </div>
      <div class="sz-StatusConfiguration-setTable-wrapper">
        <table class="sz-StatusConfiguration-table sticky-header">
          <thead>
            <tr class="sz-StatusConfiguration-setTable-headers">
              <th
                v-for="header of headers"
                :key="header"
                :class="`sz-StatusConfiguration-setTable-headers-header-${header}`"
              >
                <div class="sz-StatusConfiguration-setTable-headers-header-content">
                  <span>
                    {{ $t(`deviceManagement.statusConfiguration.headers.${header}`) }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loadingSetStatuses || !statuses.length" class="sz-StatusConfiguration-empty">
              <td class="sz-StatusConfiguration-empty-cell" :colspan="headers.length">
                <span v-if="loadingSetStatuses" class="sz-StatusConfiguration-empty-cell-loading">
                  {{ $t(`deviceManagement.statusConfiguration.loading`) }}
                </span>
                <span
                  v-else-if="!statuses.length"
                  class="sz-StatusConfiguration-empty-cell-loading"
                >
                  {{ $t(`deviceManagement.statusConfiguration.empty`) }}
                </span>
              </td>
            </tr>
            <tr
              v-for="status of processedStatuses"
              v-if="!loadingSetStatuses && statuses.length"
              :key="status.setID"
              class="sz-StatusConfiguration-setTable-row"
            >
              <td
                v-for="header of headers"
                v-if="!isDevicePosition(header)"
                :id="`sz-StatusConfiguration-setTable-row-item-${header}-${status.setID}`"
                :key="header"
                :class="`sz-StatusConfiguration-setTable-row-item ${header}`"
              >
                {{ status[header] ? status[header] : null }}
              </td>
              <td
                v-for="header of headers"
                v-if="isDevicePosition(header)"
                :id="`sz-StatusConfiguration-setTable-row-item-${header}-${status.setID}`"
                :key="header"
                @click="openDeviceStatus(status[header])"
              >
                <SvgIcon
                  v-if="isDeviceActive(status[header])"
                  icon="circle"
                  width="0.9rem"
                  height="0.9rem"
                  :class="`sz-StatusConfiguration-setTable-row-item-status-${status[header].statusCode}`"
                />
                <SvgIcon
                  v-else
                  icon="remove"
                  width="0.9rem"
                  height="0.9rem"
                  :class="`sz-StatusConfiguration-setTable-row-item-status-disabled`"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showDeviceStatus" class="sz-StatusConfiguration-deviceStatus">
      <DeviceStatus :device="selectedDevice" @closeStatus="closeStatus" />
    </div>
  </div>
</template>

<script>
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import { cloneDeep, get } from 'lodash'
import SvgIcon from 'components/Shared/SvgIcon'
import { tooltip } from 'directives/tooltip'
import DeviceStatus from 'components/DeviceManagementDashboard/StatusConfiguration/DeviceStatus'

export default {
  name: 'StatusConfiguration',

  components: {
    SvgIcon,
    DeviceStatus,
  },

  directives: {
    tooltip,
  },

  data() {
    return {
      statuses: [],
      search: '',
      showDeviceStatus: false,
      selectedDevice: {},
    }
  },

  computed: {
    ...mapGetters(['loadingSetStatuses']),

    headers() {
      return constants.DEVICE_STATUS_CHARACTERISTICS
    },

    devicePositions() {
      let deviceCharacteristics = cloneDeep(constants.DEVICE_STATUS_CHARACTERISTICS)
      delete deviceCharacteristics.SET_ID
      return deviceCharacteristics
    },

    processedStatuses() {
      return this.statuses
        .map((status) => {
          let processedStatus = {}
          let setID = this.removeSetIDPrepend(status.id)
          processedStatus['setID'] = setID
          status.devices.map((device) => {
            device.setID = setID
            device.statusCode = this.calculateDeviceStatus(device)

            if (constants.DEVICE_CONFIG_ISGC_DEVICE_TYPES.includes(device.deviceType)) {
              device.pos = constants.DEVICE_STATUS_CHARACTERISTICS.PEL
              processedStatus[constants.DEVICE_STATUS_CHARACTERISTICS.PEL] = device
            } else {
              processedStatus[`${device.pos}`] = device
            }
          })

          return processedStatus
        })
        .filter(
          (status) =>
            get(status, 'setID', '')
              .trim()
              .toLowerCase()
              .indexOf(this.search.toLowerCase().trim()) > -1
        )
    },
  },

  mounted() {
    this.getAllStatuses()
  },

  methods: {
    async getAllStatuses() {
      this.statuses = await this.$store.dispatch('getAllSetStatuses')
    },

    removeSetIDPrepend(setID) {
      return setID.length > 6 ? setID.substring(2) : setID
    },

    isDevicePosition(pos) {
      return Object.keys(this.devicePositions).includes(pos)
    },

    isDeviceActive(device) {
      return !!device
    },

    openDeviceStatus(device) {
      console.log(JSON.stringify(device))
      if (device) {
        this.selectedDevice = device
        this.showDeviceStatus = true
      }
    },

    closeStatus() {
      this.showDeviceStatus = false
      this.selectedDevice = {}
    },

    /**
     * Compares a device's configuration to determine if it is the same between between desired and reported statuses
     * @param device    Object containing a device's desired status and reported status
     * @returns boolean True if each configuration id is the same between desired and reported statuses, false otherwise
     */
    isReportedAndDesiredSame(device) {
      return (
        device.desiredStatus.azure.id === device.reportedStatus.azure.id &&
        device.desiredStatus.firmware.id === device.reportedStatus.firmware.id &&
        device.desiredStatus.network.id === device.reportedStatus.network.id &&
        device.desiredStatus.operation.id === device.reportedStatus.operation.id
      )
    },

    /**
     * Calculates the status of a device given its current configuration and latest health message
     * @params device   Object containing a device's configuration and latest health message
     * @returns string  Denoting current status of the device
     */
    calculateDeviceStatus(device) {
      let statusCode = constants.DEVICE_STATUS_COLOUR_MAP.YELLOW

      if (!device.checkInTimestampUTC || device.checkInTimestampUTC < 0) {
        statusCode = constants.DEVICE_STATUS_COLOUR_MAP.UNKNOWN
      }

      if (device.profile) {
        if (this.isDeviceStatusRed(device)) {
          statusCode = constants.DEVICE_STATUS_COLOUR_MAP.RED
        } else if (this.isDeviceStatusGreen(device)) {
          statusCode = constants.DEVICE_STATUS_COLOUR_MAP.GREEN
        }
      }

      return statusCode
    },

    /**
     * Determines if a device is in a failed state
     */
    isDeviceStatusRed(device) {
      let bootStatus = device.profile.bootStatus
      let componentFault = device.profile.componentFault

      return (
        bootStatus === constants.BOOT_STATUS.FACTORY_FAIL ||
        componentFault === constants.COMPONENT_FAULT.CRITICAL_FAILURE
      )
    },

    /**
     * Determines if a device is in a OK state
     */
    isDeviceStatusGreen(device) {
      let bootStatus = device.profile.bootStatus
      let c2DStatus = device.profile.c2DStatus
      let componentFault = device.profile.componentFault
      let isReportedAndDesiredSame = this.isReportedAndDesiredSame(device)

      return (
        (bootStatus === constants.BOOT_STATUS.DYNAMIC_PASS ||
          bootStatus === constants.BOOT_STATUS.FACTORY_PASS) &&
        c2DStatus === constants.C2D_STATUS.CONFIGURATION_PASS &&
        componentFault === constants.COMPONENT_FAULT.NO_FAULT &&
        isReportedAndDesiredSame
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

table,
th,
tr,
td {
  border: none;
}

th {
  cursor: default;
  color: $color-table-row-hover-text;
}

td,
th {
  padding: 0.5rem;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  overflow: auto;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 11px;
  flex-grow: 1;
  background-color: $color-box-background;
  color: $color-unselected-text;

  &.sticky-header th {
    position: sticky;
    top: 0;
    background-color: $color-box-background;
  }
}

.sz-StatusConfiguration {
  display: flex;
  flex-direction: row;

  &-setTable {
    display: flex;
    flex-direction: column;
    width: 25rem;

    &-optionsBar {
      display: flex;
      background-color: $color-header-bar-backround;
      align-items: center;
      padding: 0.5rem;
      font-size: 13px;
      @extend %font-heading;
      @include search-bar;

      &-title {
        display: flex;
        align-items: center;
        padding-left: 0.4rem;
      }

      &-search {
        margin-left: auto;
        width: 30%;
        min-width: 10rem;
      }
    }

    &-wrapper {
      width: inherit;
      max-height: 60vh;
      overflow: scroll;
      @include scrollbar-widget;
      background-color: $color-box-background;
    }

    &-headers {
      &-header {
        &-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left;
        }

        &-setID {
          width: 10%;
          padding-left: 1rem;
        }
      }
    }

    &-row {
      &-item {
        text-align: left;

        &.setID {
          min-width: 3rem;
          padding-left: 1rem;
        }

        &-status {
          &-green {
            color: $color-risk-green;
            cursor: pointer;
          }

          &-yellow {
            color: $color-risk-yellow;
            cursor: pointer;
          }

          &-red {
            color: $color-risk-red;
            cursor: pointer;
          }

          &-unknown {
            color: $color-base-grey;
            cursor: pointer;
          }

          &-disabled {
            color: $color-base-grey;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &-deviceStatus {
    padding-left: 1rem;
  }
}
</style>
