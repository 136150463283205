<template lang="pug">
    div(class="sz-SetTable")
        div(class="sz-SetTable-optionsBar")
            div(
                @click.stop="openEditConfigurations",
                class="sz-SetTable-optionsBar-edit") {{ selectedSetsCount }}
        div(class="sz-SetTable-wrapper")
            table(class="sz-SetTable-table sticky-header")
                thead
                    tr(class="sz-SetTable-headers")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-SetTable-headers-header-${header}`")
                            div(class="sz-SetTable-headers-header-content")
                                span {{ $t(`deviceManagement.assignConfiguration.headers.${header}`) }}
                tbody
                    tr(
                        v-if="!isSetListPopulated",
                        class="sz-SetTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-SetTable-empty-cell")
                            span(class="sz-SetTable-empty-cell-loading") {{ $t(`deviceManagement.assignConfiguration.empty`) }}
                    tr(
                        v-if="isSetListPopulated",
                        v-for="set of sets",
                        :key="set.setID",
                        class="sz-SetTable-row")
                        td(
                            v-for="header of headers",
                            :key="header",
                            :id="`sz-SetTable-row-item-${header}-${set.setID}`"
                            :class="`sz-SetTable-row-item ${header}`") {{ set[header] ?  set[header] : null }}
                        td(
                            @click.stop="removeSet(set.setID)",
                            class="sz-SetTable-row-clear")
                            SvgIcon(
                                icon="close",
                                width=12,
                                height=12,
                                class="sz-ListSelectedSets-list-set-remove-icon")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import EventBus from 'src/eventBus'
    import SvgIcon from 'components/Shared/SvgIcon'
    import formValidator from 'mixins/formValidator'
    import constructModalSettings from 'mixins/modalSettings'

    export default {
        name: 'SetTable',

        components: {
            SvgIcon,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            sets: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapGetters([
            ]),

            isSetListPopulated() {
                return this.sets.length > 0
            },

            selectedSetsCount() {
                return `${this.$t(`deviceManagement.assignConfiguration.editConfigurations`)} (${this.sets.length})`
            },

            headers () {
                return constants.DEVICE_CONFIG_HEADERS
            },
        },

        mounted () {
        },

        methods: {
            removeSet(setID) {
                EventBus.$emit('SET_REMOVED', setID)
            },

            openEditConfigurations() {
                if (this.sets.length > 0) {
                    this.$store.dispatch('showModal', this.constructModalSettings(
                        constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_CONFIGURATIONS,
                        false,
                        { sets: this.sets },
                        true,
                        true)
                    )
                } else {
                    this.failModal(
                        this.$t("deviceManagement.assignConfiguration.noneSelectedMessage"),
                        this.$t("deviceManagement.assignConfiguration.noneSelectedWarning")
                    )
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: $color-table-row-hover-text;
    }

    td, th {
        padding: 0.5rem;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;

    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top: 0;
            background-color: $color-box-background;
        }
    }

    .sz-SetTable {
        display: flex;
        flex-direction: column;
        min-width: 32.5rem;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem;
            font-size: 13px;
            @extend %font-heading;

            &-edit {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        &-wrapper {
            width: inherit;
            max-height: 60vh;
            overflow: scroll;
            @include scrollbar-widget;
            background-color: $color-box-background;
        }

        &-headers {
            &-header {
                &-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                }

                &-arrow {
                    visibility: hidden;
                    padding: 1px 3px;
                }

                &-setID {
                    min-width: 5rem;
                    padding-left: 1rem;
                }

                &-network {
                    min-width: 5rem;
                }

                &-firmware {
                    min-width: 5rem;
                }

                &-operations {
                    min-width: 5rem;
                }

                &-azure {
                    min-width: 5rem;
                }
            }
        }

        &-row {
            &-item {
                text-align: left;

                &.setID {
                    min-width: 5rem;
                    padding-left: 1rem;
                }

                &.network {
                    min-width: 5rem;
                }

                &.firmware {
                    min-width: 5rem;
                }

                &.operations {
                    min-width: 5rem;
                }

                &.azure {
                    min-width: 5rem;
                }
            }

            &-clear {
                &:hover {
                    background: $color-white;
                    color: $color-black;
                }

                cursor: pointer;
                color: $color-risk-red;
                font-size: 12px;
                padding: 0.6rem 0.3rem 0.25rem 0.3rem;
                max-width: 1rem;
                white-space: nowrap;
                border-radius: 3px;
            }
        }
    }

</style>
