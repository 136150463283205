<template lang="pug">
    div(class="sz-DeviceStatus")
        div(class="sz-DeviceStatus-header")
            div(class="sz-DeviceStatus-header-title") {{ $t(`deviceManagement.statusConfiguration.deviceStatus`) }}
        div(class="sz-DeviceStatus-body")
            div(class="sz-DeviceStatus-body-section")
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.setID`) + device.setID }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.deviceID`) + device.id }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.position`) + device.pos }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.lastCheckIn`) + lastCheckIn }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.bootStatus`) + bootStatus }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.c2DStatus`) + c2DStatus }}
                div(class="sz-DeviceStatus-body-section-info") {{ $t(`deviceManagement.statusConfiguration.componentFault`) + componentFault }}

                table(class="sz-ConfigTable")
                    div(class="sz-ConfigTable-headers")
                        div(class="sz-ConfigTable-header") {{ $t(`deviceManagement.statusConfiguration.configuration`) }}
                        div(class="sz-ConfigTable-header") {{ $t(`deviceManagement.statusConfiguration.reported`) }}
                        div(class="sz-ConfigTable-header") {{ $t(`deviceManagement.statusConfiguration.desired`) }}
                    div(
                        v-for="row of configurations",
                        class="sz-ConfigTable-row")
                        div(class="sz-ConfigTable-label") {{ row.label }}
                        div(class="sz-ConfigTable-cell") {{ row.reported }}
                        div(class="sz-ConfigTable-cell") {{ row.desired }}

        div(class="sz-DeviceStatus-buttons")
            div(
                @click.stop="close()",
                class="sz-DeviceStatus-buttons-close") {{ $t(`deviceManagement.statusConfiguration.close`) }}
            div(
                @click.stop="download()",
                class="sz-DeviceStatus-buttons-download") {{ $t(`deviceManagement.statusConfiguration.download`) }}
</template>

<script>
import constants from 'helpers/constants'
import moment from 'moment-timezone'
import { Parser } from 'json2csv'

export default {
  name: 'DeviceStatus',

  props: {
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      newTagName: null,
      showConfirmModal: true,
      action: '',
    }
  },

  computed: {
    configurations() {
      return [
        {
          label: this.$t(`deviceManagement.statusConfiguration.azure`),
          reported: this.device.reportedStatus.azure.description,
          desired: this.device.desiredStatus.azure.description,
        },
        {
          label: this.$t(`deviceManagement.statusConfiguration.operation`),
          reported: this.device.reportedStatus.operation.description,
          desired: this.device.desiredStatus.operation.description,
        },
        {
          label: this.$t(`deviceManagement.statusConfiguration.network`),
          reported: this.device.reportedStatus.network.description,
          desired: this.device.desiredStatus.network.description,
        },
        {
          label: this.$t(`deviceManagement.statusConfiguration.firmware`),
          reported: this.device.reportedStatus.firmware.description,
          desired: this.device.desiredStatus.firmware.description,
        },
        {
          label: this.$t(`deviceManagement.statusConfiguration.firmwareFactory`),
          reported:
            this.device.reportedStatus.firmwareFactory?.description ??
            this.$t(`deviceManagement.statusConfiguration.unknown`),
          desired: this.$t(`na`),
        },
      ]
    },

    lastCheckIn() {
      if (this.device.checkInTimestampUTC === -1) {
        return this.$t(`deviceManagement.statusConfiguration.unknown`)
      }
      return moment(this.device.checkInTimestampUTC * constants.UNIX_MILLISECONDS).format('LL LTS')
    },

    bootStatus() {
      return this.device.profile
        ? this.$t(
            `deviceManagement.statusConfiguration.bootStatuses.${this.device.profile.bootStatus}`
          )
        : this.$t(`deviceManagement.statusConfiguration.undefined`)
    },

    c2DStatus() {
      return this.device.profile
        ? this.$t(
            `deviceManagement.statusConfiguration.c2DStatuses.${this.device.profile.c2DStatus}`
          )
        : this.$t(`deviceManagement.statusConfiguration.undefined`)
    },

    componentFault() {
      return this.device.profile
        ? this.$t(
            `deviceManagement.statusConfiguration.componentFaults.${this.device.profile.componentFault}`
          )
        : this.$t(`deviceManagement.statusConfiguration.undefined`)
    },
  },

  methods: {
    close() {
      this.$emit('closeStatus')
    },

    download() {
      const json2csvParser = new Parser()
      const csv = json2csvParser.parse(this.formatForCsv(this.device))
      let anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = `${this.device.setID}-${this.device.pos}.csv`
      anchor.click()
    },

    formatForCsv(device) {
      return {
        SID: device.setID,
        SN: device.id,
        lastCheckIn:
          device.checkInTimestampUTC !== -1
            ? moment(
                this.device.reportedStatus.lastTimestampUTCModified * constants.UNIX_MILLISECONDS
              )
                .utc()
                .format('YYMMDDHHmm')
            : null,
        azureReportedDesc: device.reportedStatus.azure.description,
        azureDesiredDesc: device.desiredStatus.azure.description,
        operationReportedDesc: device.reportedStatus.operation.description,
        operationDesiredDesc: device.desiredStatus.operation.description,
        networkReportedDesc: device.reportedStatus.network.description,
        networkDesiredDesc: device.desiredStatus.network.description,
        firmwareReportedDesc: device.reportedStatus.firmware.description,
        firmwareDesiredDesc: device.desiredStatus.firmware.description,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

th {
  display: table-caption;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.sz-DeviceStatus {
  width: 32.5vw;
  position: relative;

  &-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lighten($color-body-bg, 10%);
    opacity: 0.5;
    filter: blur(1px);
    z-index: $layer-modal-loader-z-index;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $color-header-bar-backround;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 13px;
    @extend %font-heading;

    &-title {
      margin-left: 1rem;
    }
  }

  &-body {
    background-color: $color-box-background;
    width: 100%;
    height: 100%;

    &-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5rem 1rem 0.25rem 1rem;

      &-info {
        @extend %font-heading;
        font-size: 13px;
        font-weight: 100;
        padding-bottom: 0.25rem;
      }
    }
  }

  &-buttons {
    background-color: $color-box-background;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    @extend %font-topbar-heading;

    &-close {
      @extend %button-layout;
      padding: 0.5rem 1rem;
      border-radius: 0;
      background-color: $color-box-outline-dark;
      color: $color-white;
    }

    &-download {
      @extend %button-layout;
      padding: 0.5rem 1rem;
      border-radius: 0;
      background-color: $color-lifebooster-light-green;
    }
  }
}

.sz-ConfigTable {
  margin: 0.5rem 0;

  &-headers {
    background-color: $color-black;
    display: table-header-group;
    margin-bottom: 0.3rem;
  }

  &-header {
    @extend %font-heading;
    padding: 0.75rem 0.5rem;
    font-size: 0.75rem;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  &-row {
    display: table-row-group;
    padding: 0.5rem;
    background-color: $color-table-dark-background;
  }

  &-cell {
    border: 0.15rem solid $color-box-background;
    padding: 0.5rem;
    display: table-cell;
  }

  &-label {
    @extend %font-heading;
    font-size: 0.75rem;
    display: table-cell;
    border: 0.15rem solid $color-box-background;
  }
}
</style>
