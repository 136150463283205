import { FlatfileListener } from '@flatfile/listener'
import api, { FlatfileClient } from '@flatfile/api'
import { recordHook } from '@flatfile/plugin-record-hook'
import DeviceService from 'services/device.service'

import i18n from 'src/i18n'
import constants from 'helpers/constants'

const isValidSerialNumber = (sn) => {
  return /^[0-9A-Za-z]{3}-[0-9A-Za-z]{2}-[0-9A-Za-z]{2}-[0-9A-Za-z]{6}$/.test(sn)
}

const isValidSetIDFormat = (sid) => {
  return /^0x[0-9A-Za-z]{6}$/.test(sid)
}

const isValidDeviceType = (type) => {
  return Object.values(constants.DEVICE_TYPES).includes(type)
}

const isValidPosition = (position) => {
  return Object.values(constants.DEVICE_POSITIONS).includes(position)
}

const containsOnlyNumbers = (value) => {
  return /\d/.test(value)
}

const containsOnlyAlpha = (value) => {
  return /[A-Z]+/.test(value)
}

const isValidLength = (value, length) => {
  const regex = new RegExp(`^.{${length}}$`)
  return regex.test(value)
}

const isISGCDevice = (type) => {
  return constants.DEVICE_CONFIG_ISGC_DEVICE_TYPES.includes(type)
}

const isSGDDevice = (type) => {
  return constants.DEVICE_CONFIG_SGD_DEVICE_TYPES.includes(type)
}

/**
 * Validates whether all devices in each set of devices have the same device type
 * @returns     List of setIDs that contain devices with more than one device type
 */
const validateSetsAreMonoDeviceType = (results) => {
  if (!results || results.length === 0) {
    return undefined
  }

  let violatingSets = []

  // List all unique set IDs from the bulk upload
  let uniqueSets = results
    .map((record) => record[constants.DEVICE_CHARACTERISTICS.SID])
    .filter((value, index, self) => self.indexOf(value) === index)

  // Find all devices that belong to a set for each set ID
  let setDevices = uniqueSets.map((setId) => {
    return results.filter((device) => {
      return setId === device[constants.DEVICE_CHARACTERISTICS.SID]
    })
  })

  // Compare each device's device type against all other devices of the same set
  setDevices.map((set) => {
    if (set.length && set.length > 1) {
      let deviceTypeToCompareAgainst = set[0][constants.DEVICE_CHARACTERISTICS.DEVICE_TYPE]

      let validSet = set.every((device) => {
        return device[constants.DEVICE_CHARACTERISTICS.DEVICE_TYPE] === deviceTypeToCompareAgainst
      })

      if (!validSet) {
        violatingSets.push(set[0][constants.DEVICE_CHARACTERISTICS.SID])
      }
    }
  })

  return violatingSets
}

/**
 * Validates the value of each cell in a record
 * @param {a row in the csv file} record
 */
const validateRecord = (record) => {
  if (!containsOnlyNumbers(record.data.shipped)) {
    record.addError('shipped', i18n.t(`deviceManagement.errorMessage.numOnly`))
  }

  if (!isValidLength(record.data.shipped, 6)) {
    record.addError(
      'shipped',
      i18n.t(`deviceManagement.errorMessage.numRestriction`, { range: '6' })
    )
  }

  if (isSGDDevice(record.data.deviceType) && !isValidPosition(record.data.pos)) {
    record.addError(
      'pos',
      i18n.t(`deviceManagement.errorMessage.invalidPosition`, {
        positions: Object.values(constants.DEVICE_POSITIONS).join(', '),
      })
    )
  }

  if (!isValidSerialNumber(record.data.sn)) {
    record.addError('sn', i18n.t(`deviceManagement.errorMessage.deviceFormat`))
  }

  if (!isValidSetIDFormat(record.data.sid)) {
    record.addError('sid', i18n.t(`deviceManagement.errorMessage.setFormat`))
  }

  if (!containsOnlyNumbers(record.data.deploymentMode)) {
    record.addError('deploymentMode', i18n.t(`deviceManagement.errorMessage.numOnly`))
  }

  if (!isValidLength(record.data.deploymentMode, 1)) {
    record.addError(
      'deploymentMode',
      i18n.t(`deviceManagement.errorMessage.numRestriction`, { range: '1' })
    )
  }

  if (!isValidDeviceType(record.data.deviceType)) {
    record.addError(
      'deviceType',
      i18n.t(`deviceManagement.errorMessage.invalidDeviceType`, {
        deviceTypes: Object.values(constants.DEVICE_TYPES).join(', '),
      })
    )
  }

  return record
}

/**
 * Trims any leading or trailing whitespace
 * @param {a row in the csv file} record
 */
const trimWhiteSpace = (record) => {
  record.shipped = record.shipped ? record.shipped.trim() : undefined
  record.sn = record.sn ? record.sn.trim() : undefined
  record.pos = record.pos ? record.pos.trim() : undefined
  record.sid = record.sid ? record.sid.trim() : undefined
  record.deploymentMode = record.deploymentMode ? record.deploymentMode.trim() : undefined
  record.deviceType = record.deviceType ? record.deviceType.trim() : undefined

  return record
}

const validateAndReformatRecord = (record) => {
  record = trimWhiteSpace(validateRecord(record))
  console.log('Record =>', record)
  return record
}

const flatfile = new FlatfileClient()

export const listener = FlatfileListener.create((listener) => {
  listener.on('**', (event) => {
    console.log('Event =>', event)
  })

  // This record hook was all a part of the /Start-Portal-2/src/index.js
  // this "Contacts" sheet slug needs to match your "type" property from your v2 schema
  listener.use(
    recordHook('Devices', async (record) => {
      return validateAndReformatRecord(record)
    })
  )

  listener.filter({ job: 'workbook:submitAction' }, (configure) => {
    configure.on('job:ready', async ({ context: { jobId, workbookId } }) => {
      try {
        await flatfile.jobs.ack(jobId, {
          info: 'Getting started.',
          progress: 10,
        })

        console.log('UPLOADING DEVICES')

        const { data: workbookSheets } = await flatfile.sheets.list({ workbookId })

        let devices = []

        for (const [_, element] of workbookSheets.entries()) {
          const { data: records } = await api.records.get(element.id)

          devices = records.records.map((result) => {
            return {
              manufacturing: {
                shipped: parseInt(result.values.shipped.value) || null,
                sn: result.values.sn.value || null,
                pos: result.values.pos.value || '',
              },
              profile: {
                attributes: {
                  sid: result.values.sid.value || null,
                  deploymentMode:
                    result.values.deploymentMode.value === '0'
                      ? 0
                      : parseInt(result.values.deploymentMode.value),
                },
              },
              deviceType: result.values.deviceType.value || null,
            }
          })
        }

        await DeviceService.uploadDevices(devices)

        await flatfile.jobs.complete(jobId, {
          outcome: {
            message: 'This job is now complete.',
          },
        })
      } catch (error) {
        console.error('Error:', error.stack)

        await flatfile.jobs.fail(jobId, {
          outcome: {
            message: `This job encountered an error: ${error} `,
          },
        })
      }
    })
  })
})
