<template lang="pug">
    Layout(:showWorkerCard="showWorkerCard")
        DeviceManagementDashboard
</template>

<script>
    import Layout from '../layouts/main'
    import DeviceManagementDashboard from 'components/Dashboards/DeviceManagementDashboard'

    export default {
        name: "Settings",

        components: {
            Layout,
            DeviceManagementDashboard,
        },

        computed: {
            showWorkerCard () {
                return false
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>