<template lang="pug">
    div(class="sz-RenameConfiguration")
        div(class="sz-RenameConfiguration-configTable")
            div(class="sz-RenameConfiguration-configTable-optionsBar")
                div(
                    @click.stop="toggleEditUser",
                    class="sz-RenameConfiguration-configTable-optionsBar-title") {{ $t(`deviceManagement.renameConfiguration.configurations`) }}
                div(class="sz-RenameConfiguration-configTable-optionsBar-search")
                    input(
                        type="text",
                        v-model="search",
                        placeholder="Type here to search",
                        class="sz-RenameConfiguration-configTable-optionsBar-search-input")
                    SvgIcon(
                        icon="search",
                        width="0.5rem",
                        height="0.5rem",
                        class="sz-RenameConfiguration-configTable-optionsBar-search-icon")
            div(class="sz-RenameConfiguration-configTable-wrapper")
                table(class="sz-RenameConfiguration-table sticky-header")
                    thead
                        tr(class="sz-RenameConfiguration-configTable-headers")
                            th(
                                v-for="header of headers",
                                :class="`sz-RenameConfiguration-configTable-headers-header-${header}`")
                                div(class="sz-RenameConfiguration-configTable-headers-header-content")
                                    span {{ $t(`deviceManagement.renameConfiguration.headers.${header}`) }}
                    tbody
                        tr(
                            v-if="loadingConfigurations || !configurations.length",
                            class="sz-RenameConfiguration-empty")
                            td(
                                :colspan="headers.length",
                                class="sz-RenameConfiguration-empty-cell")
                                span(
                                    v-if="loadingConfigurations"
                                    class="sz-RenameConfiguration-empty-cell-loading") {{ $t(`deviceManagement.renameConfiguration.loading`) }}
                                span(
                                    v-else-if="!configurations.length"
                                    class="sz-RenameConfiguration-empty-cell-loading") {{ $t(`deviceManagement.renameConfiguration.empty`) }}
                        tr(
                            v-if="!loadingConfigurations && configurations.length",
                            v-for="configuration of parsedConfigurations",
                            :key="configuration.id",
                            class="sz-RenameConfiguration-configTable-row")
                            td(
                                v-for="header of headers",
                                :key="header",
                                @click="openEditConfiguration(configuration)",
                                :id="`sz-RenameConfiguration-configTable-row-item-${header}-${configuration.id}`",
                                :class="`sz-RenameConfiguration-configTable-row-item${ isSelectedConfig(configuration) ? '-selected' : '' } sz-RenameConfiguration-configTable-row-item ${header}`") {{ configuration[header] ?  configuration[header] : null }}
        div(
            v-if="showEditConfiguration",
            class="sz-RenameConfiguration-deviceStatus")
            EditConfiguration(
              @closeEdit="closeEditConfiguration",
              :config="selectedConfiguration",
              :key="editKey")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { cloneDeep, get } from 'lodash'
    import SvgIcon from 'components/Shared/SvgIcon'
    import EventBus from 'src/eventBus'
    import { tooltip } from 'directives/tooltip'
    import EditConfiguration from 'components/DeviceManagementDashboard/RenameConfiguration/EditConfiguration'
    import moment from 'moment-timezone'

    export default {
        name: 'RenameConfiguration',

        components: {
            SvgIcon,
            EditConfiguration,
        },

        directives: {
            tooltip,
        },

        data () {
            return {
                configurations: [],
                search: '',
                showEditConfiguration: false,
                selectedConfiguration: {},
            }
        },

        computed: {
            ...mapGetters([
                'loadingConfigurations',
            ]),

            headers() {
                return constants.DEVICE_CONFIG_RENAME_HEADERS
            },

            editKey () {
                return this.selectedConfiguration.id
            },

            parsedConfigurations() {
                return this.configurations
                    .map((config) => {
                        config.uploadDate = moment.tz((config.creationTimestampUTC * constants.UNIX_MILLISECONDS), 'UTC').format('LL LTS')
                        return config
                    }).filter((config) =>
                        (get(config, 'type', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1) ||
                        (get(config, 'description', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1)
                    ).sort((a,b) => (b.creationTimestampUTC - a.creationTimestampUTC))
            },
        },

        mounted () {
            this.getAllConfigurations()

            EventBus.$on('REFRESH_CONFIGURATIONS', () => {
                this.getAllConfigurations()
            })
        },

        beforeDestroy () {
            EventBus.$off('REFRESH_CONFIGURATIONS')
        },

        methods: {
            async getAllConfigurations() {
                this.configurations = await this.$store.dispatch('getAllConfigurations')
            },

            openEditConfiguration(config) {
                this.selectedConfiguration = config
                this.showEditConfiguration = true
            },

            closeEditConfiguration() {
                this.showEditConfiguration = false
                this.selectedConfiguration = {}
            },

            isSelectedConfig(config) {
                return this.selectedConfiguration.id === config.id
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: $color-table-row-hover-text;
    }

    td, th {
        padding: 0.5rem;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top: 0;
            background-color: $color-box-background;
        }
    }

    .sz-RenameConfiguration {
        display: flex;
        flex-direction: row;

        &-configTable {
            display: flex;
            flex-direction: column;
            width: 30rem;

            &-optionsBar {
                display: flex;
                background-color: $color-header-bar-backround;
                align-items: center;
                padding: 0.5rem;
                font-size: 13px;
                @extend %font-heading;
                @include search-bar;

                &-title {
                    display: flex;
                    align-items: center;
                    padding-left: 0.40rem;
                }

                &-search {
                    margin-left: auto;
                    width: 30%;
                    min-width: 10rem;
                }
            }

            &-wrapper {
                width: inherit;
                max-height: 60vh;
                overflow: scroll;
                @include scrollbar-widget;
                background-color: $color-box-background;
            }

            &-headers {
                &-header {
                    &-content {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: left;
                    }

                    &-type {
                        width: 5%;
                        padding-left: 1rem;
                    }

                    &-uploadDate {
                        width: 10%;
                    }

                    &-description {
                        width: 10%;
                        overflow: hidden;
                    }
                }
            }

            &-row {
                cursor: pointer;

                &:hover {
                    background: $color-table-row-hover;
                    color: $color-text;
                }

                &-item {
                    text-align: left;

                    &-selected {
                        background: $color-table-row-hover;
                        color: $color-text;
                    }

                    &.type {
                        min-width: 3rem;
                        padding-left: 1rem;
                    }

                    &.uploadDate {
                        min-width: 5rem;
                    }

                    &.description {
                        min-width: 5rem;
                    }
                }
            }
        }

        &-deviceStatus {
            padding-left: 1rem;
        }
    }
</style>
