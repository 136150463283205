<template lang="pug">
    div(class="sz-BatchJobInfo")
        div(class="sz-BatchJobInfo-header") {{ $t('deviceManagement.expandedInfo')}}
        div(class="sz-BatchJobInfo-body")
            div(class="sz-BatchJobInfo-body-info") 
                div(class="sz-BatchJobInfo-body-info-title") {{ $t('deviceManagement.expandedInfoFields.location') }}
                div(class="sz-BatchJobInfo-body-info-value") {{ jobLocation }}
            div(class="sz-BatchJobInfo-body-info") 
                div(class="sz-BatchJobInfo-body-info-title") {{ $t('deviceManagement.expandedInfoFields.timestamp') }}
                div(class="sz-BatchJobInfo-body-info-value") {{ jobTime }}
            div(class="sz-BatchJobInfo-body-info") 
                div(class="sz-BatchJobInfo-body-info-title") {{ $t('deviceManagement.expandedInfoFields.status') }}
                div(class="sz-BatchJobInfo-body-info-value") {{ jobStatus }} 
            div(class="sz-BatchJobInfo-body-info") 
                div(class="sz-BatchJobInfo-body-info-title") {{ $t('deviceManagement.expandedInfoFields.operation') }}
                div(class="sz-BatchJobInfo-body-info-value") {{ jobOperation }}
            div(class="sz-BatchJobInfo-body-info") 
                div(class="sz-BatchJobInfo-body-info-title") {{ $t('deviceManagement.expandedInfoFields.message') }}
                div(class="sz-BatchJobInfo-body-info-value") {{ jobMessage }}
</template>

<script>
export default {
    name: "BatchTable",

    props: {
        jobLocation: {
            required: false,
            type: String,
            default: 'n/a',
        },

        jobTime: {
            required: false,
            type: String,
            default: 'n/a',
        }, 
        jobStatus: {
            required: false,
            type: String,
            default: 'n/a',
        },

        jobOperation: {
            required: false,
            type: String,
            default: 'n/a',
        },
        
        jobMessage: {
            required: false,
            type: String,
            default: 'n/a',
        },
    }, 
}
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-BatchJobInfo {
        width: 100%;
        margin-top: 3rem;

        &-header {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem;
            font-size: 13px;
            @extend %font-heading;
        } 

        &-body {
            background-color: $color-box-background;
            padding: 1rem;
            max-height: 40vh;
            min-height: 9vh;
            overflow-x: hidden;
            overflow-y: auto;   
            @include scrollbar-widget;

            &-info {
                margin: 0.5rem 0.5rem;
                text-align: left;
                &-title {
                    @extend %font-heading;
                    font-size: 0.75rem;
                    color: $color-unselected-text ;
                    margin-bottom: 0.25rem;
                }

                &-value {
                    margin-bottom: 1rem;
                    // overflow-wrap: break-word;
                }
            }
        }

    }

</style>